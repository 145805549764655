import React from 'react'

export default function GraphIcon() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={25}
    height={25}
    viewBox="0 0 60 60"
    fill='#ff9db6'
  >
    <path d="M59 55.5v-39H47v39h-3v-31H32v31h-3v-23H17v23h-3v-14H2v14H1a1 1 0 1 0 0 2h58a1 1 0 1 0 0-2zm-55 0v-12h8v12H4zm15 0v-21h8v21h-8zm15 0v-29h8v29h-8zm15 0v-37h8v37h-8zM8.03 27.83a.995.995 0 0 0 .499-.134l36.269-20.94-2.27 4.99a.999.999 0 0 0 .91 1.414c.38 0 .743-.218.911-.586l3.562-7.83c.011-.025.009-.052.018-.078a.97.97 0 0 0 .044-.16c.005-.028.021-.051.023-.08.001-.012-.004-.022-.003-.034.002-.038-.002-.073-.004-.111a1.006 1.006 0 0 0-.024-.162c-.008-.038-.01-.077-.023-.114-.012-.036-.033-.066-.049-.101-.013-.023-.013-.05-.027-.074-.014-.024-.038-.038-.054-.061-.021-.031-.037-.064-.062-.092-.026-.03-.059-.051-.089-.078a1.033 1.033 0 0 0-.128-.101c-.031-.02-.059-.042-.093-.059-.011-.005-.017-.015-.028-.02-.025-.011-.052-.009-.077-.018a.968.968 0 0 0-.166-.044c-.026-.005-.047-.02-.074-.022l-8.562-.83a.996.996 0 0 0-1.092.898 1 1 0 0 0 .898 1.092l5.456.529-36.266 20.94a1 1 0 0 0 .501 1.866z" />
  </svg>
  )
}
