
export const GET_EMPLOYEES = 'GET_EMPLOYEES'
export const GET_EMPLOYEE = 'GET_EMPLOYEE'
export const UPDATE_EMPLOYEE_PROFILE = 'UPDATE_EMPLOYEE_PROFILE'
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE'
export const CREATE_EMPLOYEE_ERROR = 'CREATE_EMPLOYEE_ERROR'
export const DELETE_BY_STATUS = 'DELETE_BY_STATUS'
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE'


export const USERS_LOGIN = 'USERS_LOGIN'

export const GET_OTP = 'GET_OTP'
export const CONFIRM_OTP = 'CONFIRM_OTP'
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD'

export const USERS_REGISTER = 'USERS_REGISTER'
// export const GET_USER = 'GET_USER'

export const ADD_LEAVE = 'ADD_LEAVE'
export const GET_LEAVES = 'GET_LEAVES'

export const GENERATE_SALARYSLIP ='GENERATE_SALARYSLIP'
 