import React from 'react'

export default function SalaryExpenses() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={25}
    height={25}
    viewBox="0 0 354.796 354.796"
    fill='#ff9db6'
  >
    <path d="M265.442 109.092c-10.602-4.25-13.665-6.82-13.665-11.461 0-3.714 2.813-8.053 10.744-8.053 7.015 0 12.395 2.766 12.443 2.79a3.911 3.911 0 0 0 1.83.463 3.695 3.695 0 0 0 3.456-2.367l1.927-4.926c.671-1.795-.347-3.359-1.645-3.92-4.319-1.88-12.76-3.335-12.846-3.35-.136-.024-.609-.125-.609-.678l-.027-7.146c0-2.152-1.797-3.904-4.003-3.904h-3.457c-2.204 0-4 1.751-4 3.905l.009 7.513c0 .576-.624.826-.852.879-10.655 2.538-17.314 10.343-17.314 20.188 0 12.273 10.145 17.819 21.099 21.982 8.757 3.438 12.329 6.924 12.329 12.037 0 5.564-5.059 9.45-12.307 9.45-6.189 0-14.565-3.923-14.648-3.963a3.913 3.913 0 0 0-1.688-.382 3.74 3.74 0 0 0-3.537 2.457l-1.84 4.982c-.654 1.86.353 3.37 1.642 4.042 5.144 2.679 15.098 4.249 15.541 4.318.119.017.725.23.725.784v7.48c0 2.152 1.797 3.904 4.004 3.904h3.572c2.208 0 4.005-1.751 4.005-3.904v-7.872c0-.736.543-.801.655-.828 11.351-2.55 18.343-10.855 18.343-21.283-.003-10.711-5.951-17.632-19.886-23.137z" />
    <path d="M260.979 22.509c-51.816 0-93.818 42.005-93.818 93.818 0 51.814 42.002 93.82 93.818 93.82 51.814 0 93.817-42.006 93.817-93.82 0-51.813-42.003-93.818-93.817-93.818zm0 165.895c-39.808 0-72.076-32.271-72.076-72.076s32.268-72.075 72.076-72.075c39.806 0 72.073 32.27 72.073 72.075s-32.267 72.076-72.073 72.076zM335.733 255.61c-19.95 11.011-47.389 21.192-74.753 25.484-24.346 3.818-70.148-5.39-70.148-16.265 0-4.121 40.17 10.154 64.469 3.671 18.633-4.971 15.988-22.401 5.853-24.7-10.076-2.287-69.108-23.913-94.323-24.659-11.878-.351-41.203 4.131-55.393 6.442-4.861.791-7.909.704-8.213 5.356-1.412 21.62-4.195 65.832-5.712 88.926-.032.488.646 7.05 6.061 2.432 5.927-5.054 14.24-10.656 21.929-8.912 12.063 2.737 116.424 21.856 130.819 18.51 20.593-4.787 78.888-39.334 90.065-50.072 17.324-16.647 3.857-34.222-10.654-26.213zM74.426 224.74l-54.672-2.694c-4.221-.208-8.532 2.973-9.581 7.066L.232 319.367c-1.048 4.094 1.55 7.578 5.773 7.741l60.59-.006c4.224.163 7.942-3.151 8.266-7.365l6.654-86.958a7.458 7.458 0 0 0-7.089-8.039zM42.24 315.145c-8.349 0-15.116-6.768-15.116-15.116 0-8.349 6.768-15.116 15.116-15.116s15.116 6.768 15.116 15.116c0 8.349-6.767 15.116-15.116 15.116z" />
  </svg>
  )
}
