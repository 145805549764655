import React from 'react'

export default function GroupIcon() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 36 36"
    fill='#ff9db6'
  >
    <title>{"employee_group_line"}</title>
    <g data-name="Layer 3">
      <path d="M18.42 16.31a5.7 5.7 0 1 1 5.76-5.7 5.74 5.74 0 0 1-5.76 5.7Zm0-9.4a3.7 3.7 0 1 0 3.76 3.7 3.74 3.74 0 0 0-3.76-3.7Z" />
      <path d="M18.42 16.31a5.7 5.7 0 1 1 5.76-5.7 5.74 5.74 0 0 1-5.76 5.7Zm0-9.4a3.7 3.7 0 1 0 3.76 3.7 3.74 3.74 0 0 0-3.76-3.7ZM21.91 17.65a20.6 20.6 0 0 0-13 2 1.77 1.77 0 0 0-.91 1.6v3.56a1 1 0 0 0 2 0v-3.43a18.92 18.92 0 0 1 12-1.68Z" />
      <path d="M33 22h-6.7v-1.48a1 1 0 0 0-2 0V22H17a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V23a1 1 0 0 0-1-1Zm-1 10H18v-8h6.3v.41a1 1 0 0 0 2 0V24H32Z" />
      <path d="M21.81 27.42h5.96v1.4h-5.96zM10.84 12.24a18 18 0 0 0-7.95 2A1.67 1.67 0 0 0 2 15.71v3.1a1 1 0 0 0 2 0v-2.9a16 16 0 0 1 7.58-1.67 7.28 7.28 0 0 1-.74-2ZM33.11 14.23a17.8 17.8 0 0 0-7.12-2 7.46 7.46 0 0 1-.73 2A15.89 15.89 0 0 1 32 15.91v2.9a1 1 0 1 0 2 0v-3.1a1.67 1.67 0 0 0-.89-1.48ZM10.66 10.61v-.67a3.07 3.07 0 0 1 .54-6.11 3.15 3.15 0 0 1 2.2.89 8.16 8.16 0 0 1 1.7-1.08 5.13 5.13 0 0 0-9 3.27 5.1 5.1 0 0 0 4.7 5 7.42 7.42 0 0 1-.14-1.3ZM24.77 1.83a5.17 5.17 0 0 0-3.69 1.55 7.87 7.87 0 0 1 1.9 1 3.14 3.14 0 0 1 4.93 2.52 3.09 3.09 0 0 1-1.79 2.77 7.14 7.14 0 0 1 .06.93 7.88 7.88 0 0 1-.1 1.2 5.1 5.1 0 0 0 3.83-4.9 5.12 5.12 0 0 0-5.14-5.07Z" />
    </g>
  </svg>
  )
}
